import React from "react";

// Style
import '../../styles/sectionComponent.scss'

const Section = ({children, backgroundColor, backgroundComponent, className, headline}) =>{

  return(
    <section className={`main-section ${className}`}>
      <div style={{backgroundColor}} className="section-background">
        {backgroundComponent}
      </div>
      <div className="section-content">
        {headline && 
          <div className="section-content__headline-wrapper">
            <h2 className="headline">
              {headline}
            </h2>
          </div>
        }
        {children}
      </div>
    </section>
  )
}

export default Section