import React, { createContext, useContext, useState } from "react";

const ModalContext = createContext();

export const ModalProvider = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [modalContent, setModalContent] = useState(null);
  const [headline, setHeadline] = useState(null);

  const openModal = (content, headline) => {
    setModalContent(content);
    setHeadline(headline);
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
    setHeadline(null);
    setModalContent(null);
  };

  return (
    <ModalContext.Provider value={{ isOpen, modalContent, headline, openModal, closeModal }}>
      {children}
    </ModalContext.Provider>
  );
};

export const useModal = () => useContext(ModalContext);
