import React from 'react';
import PropTypes from 'prop-types';
import '../../styles/mainButton.scss'; // Import your button styles

const MainButton = ({
  styleType = 'filled', // Custom style type: filled, bordered, etc.
  color = 'primary',    // Default color class
  onClick,
  children,
  type,
  disabled
}) => {
  // Combine base and dynamic classes
  const buttonClass = `main-button ${styleType} ${color} ${disabled ? 'disabled' : ''}`;
  console.log("Disabled: ", disabled);
  return (
    <button 
    className={buttonClass} 
    onClick={disabled ? undefined : onClick} 
    type={type} 
    disabled={disabled}
    aria-disabled={disabled}
    >
      {children}
    </button>
  );
};

MainButton.propTypes = {
  styleType: PropTypes.string,
  color: PropTypes.string,
};

export default MainButton;