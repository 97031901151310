import React from 'react'
import { ModalProvider } from './src/contexts/ModalContext'
import { LanguageProvider } from './src/contexts/LanguageContext'
import { ParallaxProvider } from 'react-scroll-parallax';
import CookieBanner from './src/components/page-components/CookieBanner';

export const wrapRootElement = ({ element }) => {
  return (
    <ModalProvider>
    <LanguageProvider>
      <ParallaxProvider>
        <CookieBanner/>
        {element}
      </ParallaxProvider>
    </LanguageProvider>
    </ModalProvider>
  )
}