import React, { useState, useEffect } from "react";
import Cookies from "js-cookie";
import MainButton from "../ui-components/MainButton";
import Section from "./Section";
//import { Link } from 'gatsby'
import { Link } from 'gatsby-plugin-react-i18next'
import { useLanguage } from "../../contexts/LanguageContext";

import "../../styles/cookieBannerComponent.scss";

const CookieBanner = () => {
  const { language } = useLanguage();
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const consent = Cookies.get("cookie-consent");
    if (!consent) setIsVisible(true);
  }, []);

  const acceptCookies = () => {
    Cookies.set("cookie-consent", "accepted", { expires: 365 });
    setIsVisible(false);
  };

  const rejectCookies = () => {
    Cookies.set("cookie-consent", "rejected", { expires: 365 });
    setIsVisible(false);
  };

  const cookieMessage = {
    en:(
      <>
        <h2>We Value Your Privacy</h2>
        <p>
          We use cookies to enhance your browsing experience, analyze site traffic, and provide content. Some cookies are essential for the site to function, while others help us optimize and improve your experience.
        </p>
        <p>
          You can manage your cookie preferences or learn more about our policies on our <Link to="/imprint">Imprint page</Link>. By clicking "Accept," you consent to our use of cookies. By clicking "Reject," only essential cookies will be used.
        </p>
      </>
    ),
    de:(
      <>
        <h2>Wir schätzen Ihre Privatsphäre</h2>
        <p>
          Wir verwenden Cookies, um Ihr Surferlebnis zu verbessern, den Website-Verkehr zu analysieren und Inhalte bereitzustellen. Einige Cookies sind für die Funktion der Website unerlässlich, während andere uns helfen, Ihre Erfahrung zu optimieren und zu verbessern.
        </p>
        <p>
          Sie können Ihre Cookie-Einstellungen verwalten oder mehr über unsere Richtlinien in unserem <Link to="/imprint">Impressum</Link> erfahren. Durch Klicken auf "Akzeptieren" stimmen Sie der Verwendung von Cookies zu. Durch Klicken auf "Ablehnen" werden nur essenzielle Cookies verwendet.
        </p>
      </>
    ),
    it:(
      <>
        <h2>Valorizziamo la tua privacy</h2>
        <p>
          Utilizziamo i cookie per migliorare la tua esperienza di navigazione, analizzare il traffico del sito e fornire contenuti. Alcuni cookie sono essenziali per il funzionamento del sito, mentre altri ci aiutano a ottimizzare e migliorare la tua esperienza.
        </p>
        <p>
          Puoi gestire le tue preferenze sui cookie o saperne di più sulle nostre politiche nella nostra <Link to="/imprint">pagina Imprint</Link>. Cliccando su "Accetta," acconsenti all'uso dei cookie. Cliccando su "Rifiuta," verranno utilizzati solo i cookie essenziali.
        </p>
      </>
    ),
  }

  if (!isVisible) return null;

  return (
      <Section className="cookie-banner moveInFromBottom">
        <div className="cookie-banner-content">
          <div className="cookie-banner-wrapper">
            <div className='cookie-banner-description'>
              {cookieMessage[language] || cookieMessage.en}
            </div>
            <div className='cookie-banner-buttons'>
              <MainButton
                styleType="filled"
                color="gradient"
                className="button-wrapper"
                onClick={acceptCookies}>
                  Accept
              </MainButton>
              <MainButton
                color="secondary-color"
                className="button-wrapper"
                onClick={rejectCookies}>
                  Reject
              </MainButton>
            </div>  
          </div>
        </div>
      </Section>
  );
};

export default CookieBanner;
